import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/graduation/graduation-condition',
    name: 'graduation_condition',
    component: () => import('@/views/graduation/GraduationCondition.vue'),
    meta: {
      pageTitle: 'Xét duyệt làm khóa luận',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Xét duyệt làm khóa luận',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_CONDITION,
    },
  },
  {
    path: '/graduation/graduation-class',
    name: 'graduation_class',
    component: () => import('@/views/graduation/GraduationClass.vue'),
    meta: {
      pageTitle: 'Xếp loại tốt nghiệp',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: true,
        },
        {
          text: 'Xếp loại tốt nghiệp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_CLASS,
    },
  },
  {
    path: '/graduation/graduate-dissertation',
    name: 'graduateDissertation',
    component: () => import('@/views/admin/graduate-dissertation/Index.vue'),
    meta: {
      pageTitle: 'Đề tài khóa luận tốt nghiệp',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Đề tài khóa luận tốt nghiệp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_DISSERTATION,
    },
  },
  {
    path: '/graduationProjectRegisterRound',
    name: 'graduationProjectRegisterRound',
    component: () => import('@/views/graduation-project-register-round/GraduationProjectRegisterRound.vue'),
    meta: {
      pageTitle: 'Danh sách đợt đăng ký KLTN',
      breadcrumb: [
        {
          text: 'Quản lý đợt đăng ký KLTN',
          active: false,
        },
        {
          text: 'Quản lý đợt đăng ký KLTN',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_PROJECT_REGISTER_ROUND,
    },
  },
]
