export const ROOM_TYPES = [
  { value: 1, label: 'Phòng lý thuyết' },
  { value: 2, label: 'Phòng máy tính' },
  { value: 3, label: 'Phòng thí nghiệm' },
  { value: 4, label: 'Phòng thực hành' },
]

export const SUBJECT_TYPES = [
  { value: 'TC', label: 'Thể chất' },
  { value: 'QP', label: 'Quốc phòng' },
]

export const SCORE_TYPES = [
  { value: 1, text: 'Lý thuyết' },
  { value: 2, text: 'Thực hành' },
]

export const ResearchRoleType = Object.freeze({
  MEMBER: 1,
  REVIEWER: 2,
})

export const OSType = Object.freeze({
  WEB: 3,
})

export const LOGIN_TYPE = Object.freeze({
  NORMAL: '1',
  SSO: '2',
})

export const EVALUATED_OBJECT_GROUP_TYPES_FOR_STUDENT_CREDIT_CLASS = [
  'LTC',
  'CLPH',
]

export const OBJECT_GROUP_TYPES = [
  { value: 1, label: 'Sinh viên lớp tín chỉ' },
  { value: 2, label: 'Sinh viên, giảng viên và cán bộ' },
]

 // Object types of evaluate object group
export const OBJECT_TYPES = [
  { value: 1, label: 'Sinh viên lớp tín chỉ' },
  { value: 2, label: 'Sinh viên Lớp cố định' },
  { value: 3, label: 'Giảng viên' },
  { value: 4, label: 'Cán bộ' },
]

export const CRITERIA_TYPES = [
  { value: 1, label: 'Thanh kéo điểm' },
  { value: 2, label: 'Lựa chọn' },
  { value: 3, label: 'Tự luận' },
]

export const MULTIPLE_CRITERIA_OPTION_TYPES = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
]

export const REQUIRED = [
  { value: 0, label: 'Không bắt buộc trả lời' },
  { value: 1, label: 'Có bắt buộc trả lời' },
]

export const EVALUATE_TYPE = [
  { value: 1, label: 'Đánh giá lớp tín chỉ' },
  { value: 2, label: 'Khảo sát/đánh giá chung' },
]
