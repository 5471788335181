import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
    namespaced: true,
    state: {
        examinationRooms: [],
        totalRows: 0,
        allRoom: [],
        allSubject: [],
        listCourseSemesterSubject: [],
        listCourseSemesterSubjectThiLai: [],
        allTrainingSystems: [],
        coursesSemesters: [],
        roomBySupervisor: [],
        totalRoomBySupervisor: 0,
    },
    getters: {
        examinationRooms: state => state.examinationRooms,
        totalRows: state => state.totalRows,
        allRoom: state => state.allRoom,
        allSubject: state => state.allSubject,
        listCourseSemesterSubject: state => state.listCourseSemesterSubject,
        listCourseSemesterSubjectThiLai: state => state.listCourseSemesterSubjectThiLai,
        allTrainingSystems: state => state.allTrainingSystems,
        coursesSemesters: state => state.coursesSemesters,
        roomBySupervisor: state => state.roomBySupervisor,
        totalRoomBySupervisor: state => state.totalRoomBySupervisor,
    },
    mutations: {
        SET_ROOMS: (state, data) => {
            state.allRoom = data
        },
        SET_SUBJECTS: (state, data) => {
            state.allSubject = data
        },
        SET_TRAINING_SYSTEMS: (state, data) => {
            state.allTrainingSystems = data
        },
        SET_COURSE_SEMESTER_SUBJECT: (state, { data }) => {
            state.listCourseSemesterSubject = data
        },
        SET_COURSE_SEMESTER_SUBJECT_THILAI: (state, { data }) => {
            state.listCourseSemesterSubjectThiLai = data
        },
        SET_COURSESSEMESTERS: (state, { data }) => {
            state.coursesSemesters = data
        },
        SET_EXAMINATIONROOMS: (state, { total, records }) => {
            state.totalRows = total
            state.examinationRooms = records
        },
        SET_ROOM_BY_SUPERVISOR: (state, { total, records }) => {
            state.totalRoomBySupervisor = total
            state.roomBySupervisor = records
        },
    },
    actions: {
        async getAllRooms({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/rooms', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_ROOMS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getAllSubjects({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/subjects', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const  subjects  = data.data
                    commit('SET_SUBJECTS', subjects)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getTrainingSystemsByOrganizationId({ commit }, params) {
            try {
                const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_TRAINING_SYSTEMS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getExaminationRooms({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_EXAMINATIONROOMS', { total, records })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createExaminationRoom({ commit }, params) {
            try {
                const response = await axios.post('uni/examinationRooms', params)
                const { data } = response
                const isSuccessful = data.code === ApiCode.SUCCESS
                if (isSuccessful) {
                    return { isSuccessful, message: data.message }
                }
                return { isSuccessful, message: data.debugMessage }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateExaminationRoom({ commit }, params) {
            try {
                const response = await axios.put(`uni/examinationRooms/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async deleteExaminationRoom({ commit }, id) {
            try {
                const response = await axios.delete(`uni/examinationRooms/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async insertExaminationRooms({ commit }, params) {
            try {
                const response = await axios.post('uni/examinationRooms/createExaminationRoomCdspHoaBinh', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async createHeaderStudentExaminationRoom({ commit }, params) {
            try {
                const response = await axios.put('uni/examinationRooms/create_header', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async createHeaderStudentExaminationRoomAgain({ commit }, params) {
            try {
                const response = await axios.put('uni/examinationRooms/create_header_again', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getCourseSemesterSubject({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/courseSemesterSubject', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_COURSE_SEMESTER_SUBJECT', { data: response.data.data })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getCourseSemesterSubjectThiLai({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/courseSemesterSubjectReTest', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_COURSE_SEMESTER_SUBJECT_THILAI', { data: response.data.data })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getCourseSemesters({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/course_semesters', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_COURSESSEMESTERS', { data: response.data.data })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getScoreLockExaminationRooms({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/scoreLock', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_EXAMINATIONROOMS', { total, records })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async updateScoreLockExaminationRooms({ commit }, params) {
            try {
                const response = await axios.put('uni/examinationRooms/scoreLock', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },

        // eslint-disable-next-line no-unused-vars
        async exportPdf({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/exportPdfStudentsExaminationRoom', { params, responseType: 'blob' })
                if (response.status === 200) {
                    return response.data
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },

        async getRoomBySupervisor({ commit }, params) {
            try {
                const response = await axios.get('uni/examinationRooms/rooms-by-supervisor', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_ROOM_BY_SUPERVISOR', { total, records })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
