export const RoleCode = Object.freeze({
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  ADMIN: 'ADMIN',
})

export const PermissionCode = Object.freeze({
  MANAGE: 'manage',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  PRINT: 'PRINT',
  FETCH: 'FETCH',
})

export const ResourceCode = Object.freeze({
  ALL: 'all',
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_ROLE: 'ACCOUNT_ROLE',
  PERMISSION: 'PERMISSION',
  RESOURCE: 'RESOURCE',
  ROLE: 'ROLE',
  ROLE_PERMISSION_RESOURCE: 'ROLE_PERMISSION_RESOURCE',
  MENU: 'MENU',
  SCORE_LOCK_CREDIT_CLASS: 'SCORE_LOCK_CREDIT_CLASS',
  SCORE_LOCK_EXAMINATION_ROOM: 'SCORE_LOCK_EXAMINATION_ROOM',
  STUDENT_CONDUCT: 'STUDENT_CONDUCT',
  CONDUCT: 'CONDUCT',
  CONDUCT_CRITERIA: 'CONDUCT_CRITERIA',
  PROCESS_SCORE: 'PROCESS_SCORE',
  CHECK_EXAMINATION_PAPER: 'CHECK_EXAMINATION_PAPER',
  SYNC_POINT_LMS: 'SYNC_POINT_LMS',
  EXAMINATION_POINT: 'EXAMINATION_POINT',
  CREDIT_CLASS_DOCUMENT: 'CREDIT_CLASS_DOCUMENT',
  EXAMINATION_ROOM_TEACHER: 'EXAMINATION_ROOM_TEACHER',
  CREDIT_CLASS: 'CREDIT_CLASS',
  STUDENT_SUSPEND: 'STUDENT_SUSPEND',
  EXAMINATION_SCHEDULER: 'EXAMINATION_SCHEDULER',
  REMIX_EXAMINATION_HEADER: 'REMIX_EXAMINATION_HEADER',
  MANUAL_EXAMINATION_POINT: 'MANUAL_EXAMINATION_POINT',
  PROVISION_SCORE: 'PROVISION_SCORE',
  DEPARTMENT: 'DEPARTMENT',
  TEACHER_TEAM: 'TEACHER_TEAM',
  EMPLOYEE: 'EMPLOYEE',
  TEACHERS: 'TEACHERS',
  TEACHER_SUBJECT: 'TEACHER_SUBJECT',
  ARTICLES: 'ARTICLES',
  DOCUMENTS: 'DOCUMENTS',
  COURSE: 'COURSE',
  COURSE_SEMESTER: 'COURSE_SEMESTER',
  MAJOR: 'MAJOR',
  SUBJECT: 'SUBJECT',
  PROGRAMME: 'PROGRAMME',
  PROGRAMME_SUBJECTS: 'PROGRAMME_SUBJECTS',
  PROGRAMME_SUBJECT_GROUPS: 'PROGRAMME_SUBJECT_GROUPS',
  SUPERVISOR_EXAMINATION_ROOM: 'SUPERVISOR_EXAMINATION_ROOM',
  CREDIT_CLASS_ROOM: 'CREDIT_CLASS_ROOM',
  CREDIT_CLASS_TEACHER: 'CREDIT_CLASS_TEACHER',
  CREDIT_CLASS_STUDENT: 'CREDIT_CLASS_STUDENT',
  STUDENT_CREDIT_CLASS: 'STUDENT_CREDIT_CLASS',
  STUDENT_TRANSFER: 'STUDENT_TRANSFER',
  DIPLOMA: 'DIPLOMA',
  CLASS_DIPLOMA_PLAN: 'CLASS_DIPLOMA_PLAN',
  GRADUATION_CONDITION: 'GRADUATION_CONDITION',
  GRADUATION_CLASS: 'GRADUATION_CLASS',
  SCHEDULE: 'SCHEDULE',
  TEACHER_DOCUMENT: 'TEACHER_DOCUMENT',
  CLASSES: 'CLASSES',
  STUDENT_CLASS: 'STUDENT_CLASS',
  IMPORT_STUDENT: 'IMPORT_STUDENT',
  IMPORT_EMPLOYEE: 'IMPORT_EMPLOYEE',
  IMPORT_STUDENT_PROGRAMME: 'IMPORT_STUDENT_PROGRAMME',
  STUDENTAWARD: 'STUDENTAWARD',
  STUDENTDISCIPLINE: 'STUDENTDISCIPLINE',
  STUDENTSCHOLARSHIP: 'STUDENTSCHOLARSHIP',
  STUDENTALLOWANCE: 'STUDENTALLOWANCE',
  STUDENT_POLICIES: 'STUDENT_POLICIES',
  EXAMINATION_SCHEDULER_SUBJECT: 'EXAMINATION_SCHEDULER_SUBJECT',
  EXAMINATION_ROOM: 'EXAMINATION_ROOM',
  CDSPHOABINH_EXAMINATION_ROOM: 'CDSPHOABINH_EXAMINATION_ROOM',
  STUDENT_EXAMINATION_ROOM: 'STUDENT_EXAMINATION_ROOM',
  HEADER_CODE_EXAM: 'HEADER_CODE_EXAM',
  REVENUETYPES: 'REVENUETYPES',
  REVENUE: 'REVENUE',
  REVENUE_PLAN: 'REVENUE_PLAN',
  EXEMPTION: 'EXEMPTION',
  REVENUE_SCHEDULE: 'REVENUE_SCHEDULE',
  RECEIPT: 'RECEIPT',
  CALCULATE_CGPA: 'CALCULATE_CGPA',
  REGISTER_SUBJECT: 'REGISTER_SUBJECT',
  MAINTENANCE: 'MAINTENANCE',
  LOG_ACTIVITIES: 'LOG_ACTIVITIES',
  SEMESTER: 'SEMESTER',
  FACT_ORGANIZATION: 'FACT_ORGANIZATION',
  BUILDING: 'BUILDING',
  ROOMS: 'ROOMS',
  TRAININGFORM: 'TRAININGFORM',
  FACT_TITLE: 'FACT_TITLE',
  TRAININGSYSTEM: 'TRAININGSYSTEM',
  SEMESTERTYPE: 'SEMESTERTYPE',
  FACT_INFRINGE: 'FACT_INFRINGE',
  FACT_DISCIPLINE: 'FACT_DISCIPLINE',
  FACT_AWARD: 'FACT_AWARD',
  FACT_SCHOLARSHIP: 'FACT_SCHOLARSHIP',
  ALLOWANCE: 'ALLOWANCE',
  SUBJECT_TYPE: 'SUBJECT_TYPE',
  FACT_POLICY: 'FACT_POLICY',
  RESEARCH: 'RESEARCH',
  FACT_RESEARCH_ROLE: 'FACT_RESEARCH_ROLE',
  FACT_RESEARCH_FIELD: 'FACT_RESEARCH_FIELD',
  RESEARCH_REVIEW_BOARD: 'RESEARCH_REVIEW_BOARD',
  RESEARCH_MEMBER: 'RESEARCH_MEMBER',
  RESEARCH_REVIEWER: 'RESEARCH_REVIEWER',
  FACT_SCIENTIFIC_TOPIC: 'FACT_SCIENTIFIC_TOPIC',
  FACT_TOPIC_LEVEL: 'FACT_TOPIC_LEVEL',
  ALL_CREDIT_CLASSES: 'ALL_CREDIT_CLASSES',
  RESEARCHES_REVIEW_BOARDS: 'RESEARCHES_REVIEW_BOARDS',
  APPROVAL_RESEARCH: 'APPROVAL_RESEARCH',
  REPORT: 'REPORT',
  REPORT_TEACHING_EXAM_CONDITION: 'REPORT_TEACHING_EXAM_CONDITION',
  REPORT_COUNT_STUDENT_REGISTER_SUBJECT: 'REPORT_COUNT_STUDENT_REGISTER_SUBJECT',
  REPORT_STUDENT_CANCEL_SUBJECT: 'REPORT_STUDENT_CANCEL_SUBJECT',
  REPORT_POINT_BY_CREDIT_CLASS: 'REPORT_POINT_BY_CREDIT_CLASS',
  REPORT_AVG_STUDENT_CLASS: 'REPORT_AVG_STUDENT_CLASS',
  REPORT_CLASS_RECEIPTS_COURSE_SEMESTER: 'REPORT_CLASS_RECEIPTS_COURSE_SEMESTER',
  REPORT_PAYMENT_STATUS_BY_COURSE_SEMESTER: 'REPORT_PAYMENT_STATUS_BY_COURSE_SEMESTER',
  REPORT_CREDIT_CLASS_STUDENT_POINT: 'REPORT_CREDIT_CLASS_STUDENT_POINT',
  REPORT_TEACHER_SPECIALIZATION_ASSIGNMENT: 'REPORT_TEACHER_SPECIALIZATION_ASSIGNMENT',
  REPORT_CLASS_LEARNING_ALERT: 'REPORT_CLASS_LEARNING_ALERT',
  REPORT_CREDIT_CLASS_BY_SUBJECT: 'REPORT_CREDIT_CLASS_BY_SUBJECT',
  REPORT_RETAKE_EXAM_SECOND_TIME: 'REPORT_RETAKE_EXAM_SECOND_TIME',
  REPORT_STUDENT_BE_REPEATED: 'REPORT_STUDENT_BE_REPEATED',
  REPORT_SEMESTER_EXAM: 'REPORT_SEMESTER_EXAM',
  REPORT_STUDENTS_REGISTERED_BY_SUBJECT: 'REPORT_STUDENTS_REGISTERED_BY_SUBJECT',
  REPORT_CERTIFICATION: 'REPORT_CERTIFICATION',
  REPORT_CONFIGURATION: 'REPORT_CONFIGURATION',
  SCORE_LOCK_PASSWORD: 'SCORE_LOCK_PASSWORD',
  EXAMINATION_DEDUCT_LEVEL: 'EXAMINATION_DEDUCT_LEVEL',
  GRANT_SUPERVISOR: 'GRANT_SUPERVISOR',
  SCORING_FORMULA: 'SCORING_FORMULA',
  GENERAL_SCORE: 'GENERAL_SCORE',
  COMPONENT_SCORE: 'COMPONENT_SCORE',
  RESEARCH_INVENTION: 'RESEARCH_INVENTION',
  RESEARCH_INVENTION_MEMBER: 'RESEARCH_INVENTION_MEMBER',
  RESEARCH_TECH_TRANSFER: 'RESEARCH_TECH_TRANSFER',
  RESEARCH_TECH_TRANSFER_MEMBER: 'RESEARCH_TECH_TRANSFER_MEMBER',
  RESEARCH_STUDENT: 'RESEARCH_STUDENT',
  RESEARCH_STUDENT_MEMBER: 'RESEARCH_STUDENT_MEMBER',
  RESEARCH_INSTRUCTOR: 'RESEARCH_INSTRUCTOR',
  RESEARCH_ARTICLE: 'RESEARCH_ARTICLE',
  RESEARCH_ARTICLE_MEMBER: 'RESEARCH_ARTICLE_MEMBER',
  FACT_NATIONALITY: 'FACT_NATIONALITY',
  FACT_RELIGION: 'FACT_RELIGION',
  FACT_PROVINCE: 'FACT_PROVINCE',
  FACT_STUDENT_STATUS: 'FACT_STUDENT_STATUS',
  FACT_STUDENT_CREDIT_CLASS_STATUS: 'FACT_STUDENT_CREDIT_CLASS_STATUS',
  FACT_RESEARCH_INVENTION_TYPE: 'FACT_RESEARCH_INVENTION_TYPE',
  FACT_WARD: 'FACT_WARD',
  FACT_ACADEMIC_RANK: 'FACT_ACADEMIC_RANK',
  FACT_ACADEMIC_DEGREE: 'FACT_ACADEMIC_DEGREE',
  FACT_YEAR: 'FACT_YEAR',
  SYSTEM_PARAMETER: 'SYSTEM_PARAMETER',
  FACT_ETHNIC: 'FACT_ETHNIC',
  PORTAL_ARTICLE_CATEGORY: 'PORTAL_ARTICLE_CATEGORY',
  FACT_CAROUSEL: 'FACT_CAROUSEL',
  FACT_DEPARTMENT_TYPE: 'FACT_DEPARTMENT_TYPE',
  CHARTS: 'CHARTS',
  CHART_EMPLOYEES_BY_DEPARTMENT: 'CHART_EMPLOYEES_BY_DEPARTMENT',
  CHART_STUDENT_BY_MAJOR_AND_COURSES: 'CHART_STUDENT_BY_MAJOR_AND_COURSES',
  CHART_TEACHER_AND_CREDIT_CLASS_BY_DEPARTMENT: 'CHART_TEACHER_AND_CREDIT_CLASS_BY_DEPARTMENT',
  CHART_POINT_BY_SUBJECT: 'CHART_POINT_BY_SUBJECT',
  CHART_STUDENT_BY_SUBJECT_TYPE: 'CHART_STUDENT_BY_SUBJECT_TYPE',
  CHART_SUSPEND_STUDENT: 'CHART_SUSPEND_STUDENT',
  CHART_SCORE_BY_CREDIT_CLASS: 'CHART_SCORE_BY_CREDIT_CLASS',
  CHART_CLASS_BY_COURSES_AND_DEPARTMENT: 'CHART_CLASS_BY_COURSES_AND_DEPARTMENT',
  CHART_STUDENT_BY_ITEM: 'CHART_STUDENT_BY_ITEM',
  // CHART_STUDENT_ALLOWANCE_AND_POLICY: 'CHART_STUDENT_ALLOWANCE_AND_POLICY',
  CHART_CREDIT_CLASS_BY_SUBJECT_TYPE: 'CHART_CREDIT_CLASS_BY_SUBJECT_TYPE',
  CHART_STUDENT_SCHOLARSHIP_BY_DEPARTMENT: 'CHART_STUDENT_SCHOLARSHIP_BY_DEPARTMENT',
  QR_EMPLOYEE_STUDENT: 'QR_EMPLOYEE_STUDENT',
  CHART_STUDENTS_OVERVIEW: 'CHART_STUDENTS_OVERVIEW',
  CHART_EMPLOYEES_OVERVIEW: 'CHART_EMPLOYEES_OVERVIEW',
  MAJOR_APPROVAL: 'MAJOR_APPROVAL',
  MAJOR_APPROVAL_SPECIALIZE: 'MAJOR_APPROVAL_SPECIALIZE',
  SET_OF_CRITERIA_TYPE: 'SET_OF_CRITERIA_TYPE',
  SET_OF_CRITERIA: 'SET_OF_CRITERIA',
  EVALUATION_CRITERIA: 'EVALUATION_CRITERIA',
  EVALUATE_OBJECT_GROUP: 'EVALUATE_OBJECT_GROUP',
  EVALUATED_OBJECT_GROUP: 'EVALUATED_OBJECT_GROUP',
  SET_OF_CRITERIA_OBJECT: 'SET_OF_CRITERIA_OBJECT',
  EVALUATE_MANAGEMENT: 'EVALUATE_MANAGEMENT',
  SET_OF_EVALUATE_LEVEL: 'SET_OF_EVALUATE_LEVEL',
  CRITERIA_TYPE: 'CRITERIA_TYPE',
  QUESTION_DIFFICULTY_LEVEL: 'QUESTION_DIFFICULTY_LEVEL',
  EVALUATION_PRIORITY_LEVEL: 'EVALUATION_PRIORITY_LEVEL',
  FACT_SET_OF_CRITERIA_TYPE: 'FACT_SET_OF_CRITERIA_TYPE',
  EMPLOYEE_EVALUATE: 'EMPLOYEE_EVALUATE',
  ADMISSION_ROUND: 'ADMISSION_ROUND',
  FACT_ADMISSION_FORM_TYPE: 'FACT_ADMISSION_FORM_TYPE',
  FACT_ADMISSION_SUBJECT_TYPE: 'FACT_ADMISSION_SUBJECT_TYPE',
  FACT_ADMISSION_SUBJECT: 'FACT_ADMISSION_SUBJECT',
  FACT_ADMISSION_BLOCK: 'FACT_ADMISSION_BLOCK',
  FACT_ADMISSION_ROLE: 'FACT_ADMISSION_ROLE',
  ADMISSION_COMMITTEE: 'ADMISSION_COMMITTEE',
  ADMISSION_COMMITTEE_EMPLOYEE: 'ADMISSION_COMMITTEE_EMPLOYEE',
  ADMISSION_FORM: 'ADMISSION_FORM',
  FACT_ADMISSION_CONDUCT: 'FACT_ADMISSION_CONDUCT',
  FACT_ADMISSION_CRITERIA_TYPE: 'FACT_ADMISSION_CRITERIA_TYPE',
  FACT_ADMISSION_STUDENT_TYPE: 'FACT_ADMISSION_STUDENT_TYPE',
  FACT_ADMISSION_VIOLATE_TYPE: 'FACT_ADMISSION_VIOLATE_TYPE',
  FACT_ADMISSION_DISCIPLINE: 'FACT_ADMISSION_DISCIPLINE',
  ADMISSION_THRESHOLD_SCORE: 'ADMISSION_THRESHOLD_SCORE',
  FACT_ADMISSION_CERTIFICATE_TYPE: 'FACT_ADMISSION_CERTIFICATE_TYPE',
  FACT_PRIORITY_ZONE: 'FACT_PRIORITY_ZONE',
  FACT_HIGH_SCHOOL: 'FACT_HIGH_SCHOOL',
  FACT_ACADEMIC_PERFORMANCE: 'FACT_ACADEMIC_PERFORMANCE',
  FACT_ADMISSION_LANGUAGE_CERTIFICATE_TYPE: 'FACT_ADMISSION_LANGUAGE_CERTIFICATE_TYPE',
  FACT_ADMISSION_CONFIRMATION_TYPE: 'FACT_ADMISSION_CONFIRMATION_TYPE',
  ADMISSION_MAJOR: 'ADMISSION_MAJOR',
  ADMISSION_MAJOR_FORM: 'ADMISSION_MAJOR_FORM',
  ADMISSION_PRIORITY_SCORE: 'ADMISSION_PRIORITY_SCORE',
  ADMISSION_REVENUE_TYPE: 'ADMISSION_REVENUE_TYPE',
  ADMISSION_REVENUE: 'ADMISSION_REVENUE',
  CRITERIA_SET: 'CRITERIA_SET',
  CRITERIA: 'CRITERIA',
  ADMISSION_REVENUE_PLAN: 'ADMISSION_REVENUE_PLAN',
  ADMISSION_LANGUAGE_SCORE: 'ADMISSION_LANGUAGE_SCORE',
  ADMISSION_CAROUSEL: 'ADMISSION_CAROUSEL',
  ADMISSION_CONFIRM_CERTIFICATE: 'ADMISSION_CONFIRM_CERTIFICATE',
  FORMULA_PRIORITY_SCORE: 'FORMULA_PRIORITY_SCORE',
  ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT: 'ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT',
  FORMULA_ADMISSION_SCORE: 'FORMULA_ADMISSION_SCORE',
  APPLICANT_STUDENT_PRIORITY_ORDER: 'APPLICANT_STUDENT_PRIORITY_ORDER',
  ASPIRATION_SCORE: 'ASPIRATION_SCORE',
  ADMISSION_EXAMINATION_SCHEDULER: 'ADMISSION_EXAMINATION_SCHEDULER',
  ADMISSION_EXAMINATION_SCHEDULER_SUBJECT: 'ADMISSION_EXAMINATION_SCHEDULER_SUBJECT',
  ADMISSION_EXAMINATION_ROOM: 'ADMISSION_EXAMINATION_ROOM',
  ADMISSION_SUPERVISOR_EXAMINATION_ROOM: 'ADMISSION_SUPERVISOR_EXAMINATION_ROOM',
  ADMISSION_EXAMINATION_DEDUCT_LEVEL: 'ADMISSION_EXAMINATION_DEDUCT_LEVEL',
  APPLICANT_STUDENT_EXAMINATION_ROOM: 'APPLICANT_STUDENT_EXAMINATION_ROOM',
  ADMISSION_EXAMINATION_POINT: 'ADMISSION_EXAMINATION_POINT',
  ADMISSION_CHECK_EXAMINATION_SCORE: 'ADMISSION_CHECK_EXAMINATION_SCORE',
  ADMISSION_EXAMINATION_ROOM_TEACHER: 'ADMISSION_EXAMINATION_ROOM_TEACHER',
  SUPERVISOR_ADMISSION_EXAMINATION_ROOM: 'SUPERVISOR_ADMISSION_EXAMINATION_ROOM',
  FACT_ADMISSION_SCORE_TYPE: 'FACT_ADMISSION_SCORE_TYPE',
  APPLICANT_STUDENT_ACCEPTED: 'APPLICANT_STUDENT_ACCEPTED',
  SCORE_LOCK_ADMISSION_EXAMINATION_ROOM: 'SCORE_LOCK_ADMISSION_EXAMINATION_ROOM',
  ADMISSION_STANDARD_SCORE: 'ADMISSION_STANDARD_SCORE',
  FILTER_ADMISSION_ASPIRATION_INFORMATION: 'FILTER_ADMISSION_ASPIRATION_INFORMATION',
  CONFIG_CREATE_STUDENT_CODE: 'CONFIG_CREATE_STUDENT_CODE',
  ADMISSION_REVENUE_SCHEDULE: 'ADMISSION_REVENUE_SCHEDULE',
  APPLICANT_STUDENT_CONFIRM_ADMISSION: 'APPLICANT_STUDENT_CONFIRM_ADMISSION',
  DIGITAL_LIBRARY_REPORT_READER_DETAIL: 'DIGITAL_LIBRARY_REPORT_READER_DETAIL',
  DIGITAL_LIBRARY_REPORT_NUMBER_OF_USER: 'DIGITAL_LIBRARY_REPORT_NUMBER_OF_USER',
  DIGITAL_LIBRARY_REPORT_COUNT_BORROWED_AND_RETURNED: 'DIGITAL_LIBRARY_REPORT_COUNT_BORROWED_AND_RETURNED',
  STUDENT_ABSENT_LOGS: 'STUDENT_ABSENT_LOGS',
  PAYMENT: 'PAYMENT',
  REPORT_COUNT_STUDENT_CANCEL_SUBJECT: 'REPORT_COUNT_STUDENT_CANCEL_SUBJECT',
  BACKUP_SCHEDULE: 'BACKUP_SCHEDULE',
  BAN_STUDENT_EXAM: 'BAN_STUDENT_EXAM',
  FACT_DISABILITY: 'FACT_DISABILITY',
  FACT_GENDER: 'FACT_GENDER',
  FACT_RESEARCH_ARTICLE_ROLE: 'FACT_RESEARCH_ARTICLE_ROLE',
  // Certificate
  CREDENTIAL: 'CREDENTIAL',
  CREDENTIAL_TYPE: 'CREDENTIAL_TYPE',
  CERTIFICATE_BLANK: 'CERTIFICATE_BLANK',
  CERTIFICATE_PROCESS_TYPE: 'CERTIFICATE_PROCESS_TYPE',
  CREATE_CREDENTIAL: 'CREATE_CREDENTIAL',
  CREDENTIAL_STORAGE: 'CREDENTIAL_STORAGE',
  CREDENTIAL_PROCESS_REQUEST: 'CREDENTIAL_PROCESS_REQUEST',
  CERTIFICATE_IMPLEMENT: 'CERTIFICATE_IMPLEMENT',
  CACHE: 'CACHE',
  GRADUATION_PROJECT_REGISTER_ROUND: 'GRADUATION_PROJECT_REGISTER_ROUND',
  GRADUATION_DISSERTATION: 'GRADUATION_DISSERTATION',
  REGISTER_GRADUATION_DISSERTATION: 'REGISTER_GRADUATION_DISSERTATION',
  GRADUATION_PROJECT_SCORE: 'GRADUATION_PROJECT_SCORE',
})

export default { RoleCode, PermissionCode, ResourceCode }
