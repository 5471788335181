import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'
import { GRADUATION_PROJECT_REGISTER_STATUSES } from '@/const/status'
export const defaultParams = Object.freeze({
  currentPage: 1,
  itemsPerPage: 100,
  status: 1,
  sort: '1_asc',
})
export default {
  namespaced: true,
  state: {
    graduationProjectRegisterRounds: [],
    trainingSystems: [],
    courses: [],
    courseYears: [],
    totalRows: 0,
    statuses: GRADUATION_PROJECT_REGISTER_STATUSES,
    resourceName: { fullName: 'đợt đăng ký KLTN', shortName: 'đợt đăng ký KLTN' },
  },
  getters: {
    graduationProjectRegisterRounds: state => state.graduationProjectRegisterRounds,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    courses: state => state.courses,
    trainingSystems: state => state.trainingSystems,
    courseYears: state => state.courseYears,
  },
  mutations: {
    SET_GRADUATION_PROJECT_REGISTER_ROUNDS: (state, { total, records }) => {
      state.totalRows = total
      state.graduationProjectRegisterRounds = records
    },
    SET_COURSES: (state, records) => {
      state.courses = records
    },
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_COURSE_YEARS: (state, records) => {
      state.courseYears = records
    },
  },
  actions: {
    async getGraduationProjectRegisterRounds({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationProjectRegisterRounds', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GRADUATION_PROJECT_REGISTER_ROUNDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createGraduationProjectRegisterRound({ commit }, params) {
      try {
        const response = await axios.post('uni/graduationProjectRegisterRounds', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateGraduationProjectRegisterRound({ commit }, params) {
      try {
        const response = await axios.put(`uni/graduationProjectRegisterRounds/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteGraduationProjectRegisterRound({ commit }, id) {
      try {
        const response = await axios.delete(`uni/graduationProjectRegisterRounds/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
